<template>
  <div class="suppliers-partners">
    <div class="container">
      <h2 class="title">{{ $t("respectTitle") }}</h2>
      <div class="suppliers-partners__descr">
        <p>{{ $t("respectContent") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuppliersPartners",
};
</script>
